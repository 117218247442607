const roadmap_data = [
    {
        title: 'Alpha Stage',
        active: true,
        data: [
            {
                status: true,
                task: 'Token Mining',
            },
            {
                status: false,
                task: 'First Game in App',
            },
            {
                status: false,
                task: 'Leaderboards',
            },
        ],
    },
    {
        title: 'Beta Stage',
        active: false,
        data: [
            {
                status: false,
                task: 'Exciting New Games',
            },
            {
                status: false,
                task: 'Forming Squads',
            },
            {
                status: false,
                task: 'Additional Tasks',
            },
            {
                status: false,
                task: 'In app Wallet',
            },
            {
                status: false,
                task: 'Airdrop from Partners Projects',
            },
        ],
    },
    {
        title: 'Public Release',
        active: false,
        data: [
            {
                status: false,
                task: 'Competitive Game Launches',
            },
            {
                status: false,
                task: 'Exclusive NFT Airdrop',
            },
            {
                status: false,
                task: 'Metaverse Room Launch',
            },
            {
                status: false,
                task: 'Timed Limited Events',
            },
            {
                status: false,
                task: 'Community Token Airdrops!',
            },
        ],
    },
];

export default roadmap_data;